import { LocalStorage, Notify } from "quasar"
import * as Sentry from "@sentry/vue";

/**
 * Log in
 *
 * @param context
 * @param data
 * @returns {Promise<any>}
 */
export async function login({dispatch, commit}, {email, password}) {

  commit("SET_USER_TOKEN", null)
  commit("SET_USER", null)
  commit("setInvites", null)

  return await dispatch("app/apiRequest", {
    url: "auth/login",
    method: "POST",
    data: {
      email,
      password
    }
  }, { root: true })
  .then(async response => {
    commit("SET_USER_TOKEN", response.data.token.plainTextToken)
    commit("SET_USER", response.data.user)

    // get user teams
    await dispatch("getTeams")
    await dispatch("getCurrentTeam")

    Notify.create({
      icon: "mdi-check",
      message: "Logged in",
      caption: response.data.user.name,
      timeout: 2000
    })

    // set Sentry user context
    Sentry.setUser({
      id: response.data.user.id,
      name: response.data.user?.name || `${response.data.user?.first_name} ${response.data.user?.last_name}`,
      email: response.data.user.email,
    });

    if (this.$router.currentRoute.value.query.redirect) {
      this.$router.push(this.$router.currentRoute.value.query.redirect)
    } else {
      this.$router.push("/dashboard")
    }
  }).catch(error => {

    console.log({error})

    if(error?.response?.data?.redirect){
      sessionStorage.setItem("skip_verification_resend_timer", '1');
      this.$router.push(error.response.data.redirect);
      return;
    }

    Notify.create({
      color: "negative",
      message: error.response.data.message,
      icon: "mdi-alert-circle"
    })
  });
}

/**
 * Register
 *
 * @param context
 * @param data
 * @returns {Promise<any>}
 */
export async function register({dispatch, commit}, {user}) {
  return await dispatch("app/apiRequest", {
      url: "auth/register",
      method: "POST",
      data: { ...user }
    },
    { root: true }
  ).then(response => {
    return {
      success: true,
    };
  }).catch(error => {
    Notify.create({
      color: "negative",
      message: error.response.data.message,
      icon: "mdi-alert-circle"
    })

    return {
      success: false,
    };
  });
}

/**
 * Resend verification email
 *
 * @param context
 * @param data
 * @returns {Promise<any>}
 */
export async function resend({dispatch, commit}, {email}) {
  return await dispatch("app/apiRequest", {
      url: "auth/resend",
      method: "POST",
      data: { email }
    },
    { root: true }
  ).then(response => {
    return {
      success: true,
    };
  }).catch(error => {
    Notify.create({
      color: "negative",
      message: error.response.data.message,
      icon: "mdi-alert-circle"
    })

    return {
      success: false,
    };
  });
}

/**
 * Verify email token
 *
 * @param context
 * @param data
 * @returns {Promise<any>}
 */
export async function verify({dispatch, commit}, {email, token}) {
  return await dispatch("app/apiRequest", {
      url: "auth/verify",
      method: "POST",
      data: { email, token }
    },
    { root: true }
  ).then(response => {
    return {
      success: true,
    };
  }).catch(error => {
    Notify.create({
      color: "negative",
      message: error.response.data.message,
      icon: "mdi-alert-circle"
    })

    return {
      success: false,
    };
  });
}

/**
 * Get authed user.
 *
 * @param context
 * @param data
 * @returns {Promise<any>}
 */
export async function getUser(context) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "user",
        method: "GET",
        data: {}
      },
      { root: true }
    )
    .then(async response => {
      context.commit("SET_USER", response.data.user)
    })
}

/**
 * Logout
 *
 * @param context
 * @param data
 * @returns {Promise<void>}
 */
export async function logout(context, data) {
  await context
    .dispatch(
      "app/apiRequest",
      {
        url: "auth/logout",
        method: "GET",
        data: {}
      },
      { root: true }
    )
    .finally(response => {
      Notify.create({
        icon: "mdi-check",
        message: data.successMessage ?? "Logged out"
      })

      // clear local storage
      LocalStorage.clear()

      this.$router.push("/login")
    })
}

export async function getTeams({dispatch, commit}) {
  return await dispatch(
      "app/apiRequest",
      {
        url: "teams",
        method: "GET",
        data: {}
      },
      { root: true }
    )
    .then(response => {
      commit("SET_TEAMS", response.data)
      return Promise.resolve(response.data)
    })
}

export async function getCurrentTeam({dispatch, commit}) {
  return await dispatch(
      "app/apiRequest",
      {
        url: "teams/current",
        method: "GET",
        data: {}
      },
      { root: true }
    )
    .then(response => {
      commit("SET_CURRENT_TEAM", response.data)
      return Promise.resolve(response.data)
    })
}

/**
 * Create new team.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function create_team(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "teams/create",
        method: "POST",
        data: data.data
      },
      { root: true }
    )
    .then(
      response => {
        context.commit("update", { team: response.data[0] })
        context.dispatch("getUser")
        return Promise.resolve(response.data)
      },
      error => {
        Notify.create({
          color: "negative",
          message: "There was an error: " + error.message,
          icon: "mdi-alert-circle"
        })
        return Promise.reject(error)
      }
    )
}

/**
 * Switch to different team.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function switch_team({dispatch, commit}, {team_id}) {
  return await dispatch(
      "app/apiRequest",
      {
        url: "teams/switch",
        method: "POST",
        data: {
          team_id
        }
      },
      { root: true }
    )
    .then(
      async response => {
        await dispatch("project/fetchFolders", null, { root: true })
        await dispatch("project/fetchProjects", null, { root: true })

        await commit("SET_CURRENT_TEAM", response.data.currentTeam)
        await commit("project/SET_PROJECTS", response.data.projects, {root: true })

        await Notify.create({
          message: "Team switched to " + response.data.currentTeam.name,
        })

        return Promise.resolve(response.data)
      },
      error => {
        Notify.create({
          color: "negative",
          message: "There was an error: " + error.message,
          icon: "mdi-alert-circle"
        })
        return Promise.reject(error)
      }
    )
}

/**
 * Update team info.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function update_team(context, {team}) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "teams/" + team.id,
        method: "PUT",
        data: team
      },
      { root: true }
    )
    .then(
      response => {
        context.commit("UPDATE_TEAM", response.data)
        Notify.create({ message: "Team info updated" })
        return Promise.resolve(response.data)
      },
      error => {
        Notify.create({
          color: "negative",
          message: "There was an error: " + error.message,
          icon: "mdi-alert-circle"
        })
        return Promise.reject(error)
      }
    )
}

/**
 * Delete a team.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function delete_team(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "teams/delete/" + data.data.id,
        method: "DELETE",
        data: {}
      },
      { root: true }
    )
    .then(
      response => {
        context.dispatch("getUser")
        Notify.create({ message: "Team deleted" })
        this.$router.push("/settings/teams")
        return Promise.resolve(response.data)
      },
      error => {
        Notify.create({
          color: "negative",
          message: "There was an error: " + error.message,
          icon: "mdi-alert-circle"
        })
        return Promise.reject(error)
      }
    )
}

/**
 * Get user's team invitations.
 *
 * @param context
 * @returns {Promise<unknown>}
 */
export async function get_user_team_invitations(context) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "user/invitations",
        method: "GET"
      },
      { root: true }
    )
    .then(response => {
      context.commit("setInvites", response.data.invitations)
      return Promise.resolve(response.data)
    })
}

/**
 * Accept a team invite.
 *
 * @param context
 * @returns {Promise<unknown>}
 */
export async function accept_invitation(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "teams/accept/" + data.accept_token,
        method: "GET"
      },
      { root: true }
    )
    .then(async response => {
      await context.commit("SET_USER", response.data.user)
      await context.commit("setInvites", response.data.invites)
      Notify.create({ message: "Invitation accepted" })
      return Promise.resolve(response.data)
    })
}

/**
 * Leave a team.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function leave_team(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "teams/leave/" + data.team_id,
        method: "DELETE"
      },
      { root: true }
    )
    .then(async response => {
      await context.commit("SET_USER", response.data.user)
      await context.commit("setInvites", response.data.invites)
      Notify.create({ message: "Left team" })
      this.$router.push("/settings/teams")
      return Promise.resolve(response.data)
    })
}

/**
 * Leave a team.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function change_team_owner(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "teams/" + data.team_id + "/change_owner",
        method: "PUT",
        data: {
          new_owner_id: data.new_owner_id
        }
      },
      { root: true }
    )
    .then(async response => {
      await context.commit("SET_USER", response.data.user)
      Notify.create({ message: "New owner set" })
      this.$router.push("/settings/teams")
      return Promise.resolve(response.data)
    })
}

/**
 * Fetch and save s3 signature used for direct-to-s3 uploading.
 *
 * @param context
 * @returns {Promise<unknown>}
 */
export async function getS3Signature({ dispatch, commit }) {
  console.log("getS3Signature()")

  return await dispatch(
    "app/apiRequest",
    {
      url: "s3/signature",
      method: "GET"
    },
    { root: true }
  ).then(async response => {
    await commit("UPDATE_S3_FORM_DATA", response.data)
    return Promise.resolve(response.data)
  })
}

export async function getDailyReportSettings(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "settings/get-daily-reports",
        method: "GET"
      },
      { root: true }
    )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
